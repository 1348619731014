import React, { useState } from "react"
import PropTypes from "prop-types"
import PrimaryLayout from "@src/layouts/primary"
import { UserContext } from "@src/context/user-context"
import PageHelmet from "@src/components/page-helmet"
import { normalizeTitle } from "@src/utils/page-data"
import ResponsiveSection from "@src/components/container-responsive-section"
import Form, { FormActions } from "@src/components/form"
import FormFieldEmailAddress from "@src/components/form-field-email"
import Button from "@src/components/core-button"
import Link from "@src/components/core-link"

const AccountRecoveryPage = ({ location }) => {
  const params = new URLSearchParams(location.search)
  const [email, setEmail] = useState(params.get("email"))
  const [success, setSuccess] = useState()
  const [formMessage, setFormMessage] = useState()
  return (
    <PrimaryLayout>
      <PageHelmet title={normalizeTitle("Account Recovery")} />
      <UserContext.Consumer>
        {({ recover }) => {
          const handleSubmit = ({ email }) => {
            recover(email)
              .then(() => {
                setSuccess(true)
                setFormMessage()
              })
              .catch(error => {
                setSuccess(false)
                setFormMessage(error)
              })
          }
          return success ? (
            <ResponsiveSection variant="card">
              <h1>Help is on the way!</h1>
              <p>Please check your email for further instructions.</p>
              <p>
                <Link to="/account/reset-password/">
                  Click here to reset your password
                </Link>
              </p>
            </ResponsiveSection>
          ) : (
            <ResponsiveSection variant="card">
              <h1>Forgot your password?</h1>
              <Form
                name="user-account-recovery"
                onSubmit={handleSubmit}
                variant="blue"
              >
                <FormFieldEmailAddress
                  id="email"
                  name="email"
                  placeholder="Enter your email address"
                  label="Let's recover it with an email"
                  defaultValue={email}
                  onChange={setEmail}
                  validation={{
                    required:
                      "We'll send an email with password reset instructions",
                  }}
                />
                <FormActions>
                  {formMessage && <p className="form-error">{formMessage}</p>}
                  <Button variant="rounded" type="submit">
                    Send recovery email
                  </Button>
                  <p>
                    Have a reset code?{" "}
                    <Link
                      to={`/account/reset-password/${
                        email ? "?email=" + encodeURIComponent(email) : ""
                      }`}
                    >
                      Reset your password
                    </Link>
                  </p>
                  <p>
                    Remembered your password?{" "}
                    <Link
                      to={`/account/login/${
                        email ? "?email=" + encodeURIComponent(email) : ""
                      }`}
                    >
                      Log in
                    </Link>
                  </p>
                </FormActions>
              </Form>
            </ResponsiveSection>
          )
        }}
      </UserContext.Consumer>
    </PrimaryLayout>
  )
}
AccountRecoveryPage.propTypes = {
  location: PropTypes.object,
}
export default AccountRecoveryPage
